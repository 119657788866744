<template>
  <div class="banner">
    <p class="title">出海解决方案</p>
    <p class="sub-title">急速·好用·安全·稳定·放心</p>
    <p class="intro">为出海提供一站式直播、带货的基础设施服务</p>
    <div class="buy-now" @click="buyNow">立即购买</div>
  </div>
</template>

<script>
import { getIsLogin } from "@/utils/utils";
import { checkRealName } from "@/request/user";
import { ConnectionTypeEnum } from "../../../config/system";

export default {
  data() {
    return {
      isLogin: false,
      authStatus: undefined,
    };
  },
  mounted() {
    this.isLogin = getIsLogin();
    if (this.isLogin) {
      checkRealName().then((res = {}) => {
        const { realNameAuthState } = res;
        this.authStatus = realNameAuthState;
      });
    }
  },
  methods: {
    buyNow() {
      if (!this.isLogin) {
        this.$router.push("/login");
      } else if (this.authStatus === 1) {
        this.$router.push({
          path: "/user/purchase",
          query: {
            connectionType: ConnectionTypeEnum.Direct,
          },
        });
      } else {
        this.$router.push("/user/info");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 750px;
  background: url("../../../assets/images/programme/banner.jpg") center
    no-repeat;
  background-size: cover;
  padding-top: 326px;
  padding-left: 176px;

  .title {
    color: #ffffff;
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 39px;
  }

  .sub-title {
    color: #ffffff;
    font-size: 44px;
    margin-bottom: 15px;
  }

  .intro {
    color: #a6b9fe;
    font-size: 24px;
    margin-bottom: 60px;
  }

  .buy-now {
    width: 240px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    text-align: center;
    color: #edfaff;
    font-size: 28px;
    background: linear-gradient(0, #eb7a00, #f9c431);
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.05, 1.05);
    }
  }
}
</style>
