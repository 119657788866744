<template>
  <div class="special-line-advantage">
    <p class="title">e路播专线优势</p>
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/稳定.png" />
        <span>稳定网络</span>
        <span>提供急速、好用、安全、稳定、放心的专线出海通道。</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/安全.png" />
        <span>安全合规</span>
        <span
          >依托国家数据跨境试点，拥有多年互联网安全经验，赋能大数据，完善的网络白名单机制，减少企业内部合规风险，全面尊重客户数据主权，有效保护直播企业出海业务安全。</span
        >
      </div>
      <div class="item">
        <img src="@/assets/images/programme/使用.png" />
        <span>2K超清直播</span>
        <span
          >针对海外直播场景技术优化，提供720P及1080P两种规格的直播加速服务，直播流畅不卡顿。</span
        >
      </div>
      <div class="item">
        <img src="@/assets/images/programme/IP.png" />
        <span>IP稳定</span>
        <span>提供固定IP，专号专用，提升涨粉效率，有效保障直播账户安全。</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/独享.png" />
        <span>独享带宽</span>
        <span
          >每个客户都可以享受独立的带宽，不受外部干扰，在自己的航线中翱翔。</span
        >
      </div>
      <div class="item">
        <img src="@/assets/images/programme/全球.png" />
        <span>全球覆盖</span>
        <span
          >覆盖全球的数据中心，提供伦敦、马尼拉、东京、胡志明、新加坡、曼谷、华盛顿、迪拜、法兰克福等地域线路供用户选择。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.special-line-advantage {
  height: 1068px;
  background: url("../../../assets/images/programme/special-line-advantage.jpg")
    center no-repeat;
  background-size: cover;
  padding-top: 79px;

  .title {
    color: #39425c;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 57px;
    text-align: center;
  }

  .list {
    width: 1440px;
    height: 830px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    align-content: space-between;
    .item {
      width: 420px;
      height: 400px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 24px;
      &:nth-child(1) {
        padding-top: 28px;
        img {
          width: 94px;
          margin-bottom: 9px;
        }
      }
      &:nth-child(2) {
        padding-top: 28px;
        img {
          width: 181px;
          margin-bottom: 9px;
        }
      }
      &:nth-child(3) {
        padding-top: 29px;
        img {
          width: 199px;
          margin-bottom: 10px;
        }
      }
      &:nth-child(4) {
        padding-top: 25px;
        img {
          width: 108px;
          margin-bottom: 9px;
        }
      }
      &:nth-child(5) {
        padding-top: 29px;
        img {
          width: 104px;
          margin-bottom: 9px;
        }
      }
      &:nth-child(6) {
        padding-top: 15px;
        img {
          width: 103px;
          margin-bottom: 9px;
        }
      }
      span {
        &:nth-of-type(1) {
          color: #3661eb;
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 38px;
        }
        &:nth-of-type(2) {
          font-size: 18px;
          color: #39425c;
          text-indent: 2em;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
