<template>
  <div class="live-acceleration">
    <p class="title">直播加速解决方案</p>
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/视频.png" />
        <span>网络卡顿</span>
        <span>优质网络，独享全球专线</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/不安全.png" />
        <span>不安全</span>
        <span>固定IP，保障直播安全和数据流畅</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/一键联网.png" />
        <span>麻烦</span>
        <span>简单方便，无需自己配置</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.live-acceleration {
  height: 750px;
  background: url("../../../assets/images/programme/live-acceleration.jpg")
    center no-repeat;
  background-size: cover;
  padding-top: 80px;

  .title {
    color: #39425c;
    font-size: 44px;
    text-align: center;
    margin-bottom: 60px;
  }

  .list {
    width: 1440px;
    height: 520px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .item {
      width: 420px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1) {
        padding-top: 81px;
        img {
          width: 148px;
          margin-bottom: 45px;
        }
      }
      &:nth-child(2) {
        padding-top: 61px;
        img {
          width: 231px;
          margin-bottom: 49px;
        }
      }
      &:nth-child(3) {
        padding-top: 70px;
        img {
          width: 283px;
          margin-bottom: 15px;
        }
      }
      span {
        &:nth-of-type(1) {
          color: #3661eb;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        &:nth-of-type(2) {
          color: #39425c;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
