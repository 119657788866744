<template>
  <div class="advantage-comparison">
    <p class="title">优势对比</p>
    <div class="table">
      <div>
        <div>方案</div>
        <div>传输实现方式</div>
        <div>安装方法</div>
        <div>传输延时</div>
        <div>传输质量（丢包率）</div>
        <div>IP地址</div>
        <div>是否会被封IP地址</div>
        <div>是否会被平台限流</div>
      </div>
      <div>
        <div>传统VPN模式</div>
        <div>公共互联网加密</div>
        <div>手机安装客户端软件</div>
        <div>150-2000毫秒（英美）</div>
        <div>2%-20%</div>
        <div>共用IP地址</div>
        <div>概率很高，可更换IP</div>
        <div>概率很高</div>
      </div>
      <div>
        <div>e路播专线</div>
        <div>专用的数据线路</div>
        <div>专线传输设备</div>
        <div>120-180毫秒（英美）</div>
        <div>0.05%-0.1%</div>
        <div>独享IP地址</div>
        <div>否</div>
        <div>否</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.advantage-comparison {
  height: 971px;
  background: url("../../../assets/images/programme/advantage.jpg") center
    no-repeat;
  background-size: cover;
  padding-top: 84px;

  .title {
    color: #39425c;
    font-size: 44px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 33px;
  }

  .table {
    width: 1440px;
    display: flex;
    margin: 0 auto;
    div {
      width: 480px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 24px;
      margin-top: -1px;
      margin-left: -1px;
    }
    & > div {
      &:first-child div {
        background-color: #3661eb;
        border: 1px solid #99bdf4;
        color: #d9e5ff;
      }
      &:nth-child(2),
      &:nth-child(3) {
        & > div {
          color: #39425c;
          border: 1px solid #99bdf4;
          background-color: rgba(255, 255, 255, 0.5);
          &:first-child {
            background-color: #3661eb;
            color: #ffffff;
          }
          // &:last-child {
          //   color: #f4a702;
          //   font-size: 36px;
          //   font-weight: bold;
          // }
        }
      }
    }
  }
}
</style>
